
 ::v-deep.el-upload-list__item{
   width: 200px!important;
   .el-upload-list__item-name{
     .el-icon-document{
       display: none;
     }
   }
 }
 .taskcenter_content{
   display: flex;
   flex-direction: column;
 }
 .task_content{
   .release_task{
     ::v-deep .upload-covercss{
       width: 378px;
     }
   }
 }
 .activeClass {
   background: #4d5aff;
   color: #ffffff;
 }
 .imggeshi {
   position: absolute;
   left: -70px;
   top: 24px;
   color: #999999;
   font-size: 12px;
 }
 .isbtn {
   margin-left: 47px;
   margin-top: 39px;
 }

 .task_header {
   display: flex;
   text-align: center;
   /*width: 100%;*/
 }
 .task_header p {
   color: #858ea6;
   font-size: 14px;
   width: 162px;
   height: 53px;
   line-height: 53px;
   border-radius: 6px;
   cursor: pointer;
 }
 .task_header .active {
   color: #0824ce;
   font-size: 16px;
   width: 162px;
   height: 53px;
   line-height: 53px;
   background: #ffffff;
   border-radius: 6px;
 }
 .task_header p:nth-child(1) {
   /*margin-left: 25px;*/
 }
 .task_content{
   position: relative;
 }
 .release_task {
   background: #ffffff;
   width: 100%;
   margin-top: -18px;
   overflow: hidden;
 }
 .task_list {
   background: #ffffff;
   width: 100%;
   margin-top: -18px;
   overflow: hidden;
 }

 .tasjlist_header {
   // width: 1600px;
   overflow: hidden;
   font-size: 14px;
   color: #333333;
 }
 .tasjlist_header p {
   margin-left: 33px;
 }
 .tasjlist_header div {
   text-align: center;
 }
 .tasjlist_header div:nth-child(1) {
   display: flex;
 }
 .tasjlist_header div:nth-child(1) span {
   margin-top: 8px;
   line-height: 32px;
   display: inline-block;
   height: 32px;
   padding: 0 20px;
 }

 .tasjlist_header div:nth-child(2) {
   display: flex;
 }
 .tasjlist_header div:nth-child(2) span {
   margin-top: 8px;
   line-height: 32px;
   display: inline-block;
   height: 32px;
   padding: 0 20px;
   margin-bottom: 20px;
 }
 el-form {
   margin-top: 30px;
 }

 .addimg {
   display: block;
   width: 22px;
   height: 22px;
   background: #1d33e3;
   color: #ffffff;
   border-radius: 50%;
   position: absolute;
   font-size: 18px;
   line-height: 22px;
   text-align: center;
   left: 33%;
   top: -60%;
 }
 #article_content{
   z-index: 1;
  width: 50%;
}
 .studentNameTitle{
   line-height: 1;
   margin-top: 4px;
   font-size: 12px;
 }
 .studentNameContent{
   font-size: 12px;
   color: #999999;
   line-height: 1;
   margin: 0;
     ::v-deep img{
       display: none!important;
     }
 }
 .selectClassList{
   display: flex;
   align-items: center;
   span{
     margin-right: 20px;
     position: relative;
     display: inline-block;
     i{
       cursor: pointer;
       font-weight: bold;
       font-style: normal;
       position: absolute;
       font-size: 12px;
       right: -10px;
       top: -5px;
     }
   }
 }
